import { SEARCH_OBLIGATEDS_REQUEST, SEARCH_OBLIGATEDS_SUCCESS, SEARCH_OBLIGATEDS_FAILURE } from '../types/obligated.types';

function initState() {
    return { obligateds: [], requesting: false };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case SEARCH_OBLIGATEDS_REQUEST:
            return {
                obligateds: [],
                requesting: true,
            };

        case SEARCH_OBLIGATEDS_SUCCESS:
            return {
                obligateds: payload.obligateds,
                requesting: false,
            };

        case SEARCH_OBLIGATEDS_FAILURE:
            return {
                ...state,
                requesting: false,
            };

        default:
            return state;
    }
}
