import moment from 'moment';
import { iconSizes } from '../config/sizes';

// Menu Toggle
export const convertToMainClass = (sidebarClass) => sidebarClass.replace('sidebar', 'main');

export const convertToTogglerClass = (sidebarClass) => sidebarClass.replace('sidebar', 'toggler');

export const joinClassNames = (...classNames) => classNames.join(' ');

export function isDevelopmentMode() {
    return process.env.REACT_APP_NODE_ENV === 'development';
}

export function isNullish(value) {
    return [undefined, null].includes(value);
}

export function isNonEmptyObject(object) {
    return isValidValue(object) && Object.keys(object).length > 0;
}

export function isValidValue(value) {
    return !isNullish(value) && value !== '';
}

export function formatDate(date = moment(), format = 'DD/MM/YYYY') {
    return moment(date).format(format);
}

export function removeNullishInString(value) {
    return value.replace(/\bnull\b|\bundefined\b/gi, '').replace(/  +/g, ' ');
}

export function formatName({ lastName, firstName } = {}) {
    return removeNullishInString(`${firstName} ${lastName}`.trim());
}

export function isNullishOrEmpty(value) {
    return isNullish(value) || (Array.isArray(value) && value.length === 0) || (typeof value === 'object' && Object.keys(value).length === 0) || (typeof value === 'string' && value.length === 0);
}

function roundToTwoDecimal(value) {
    return value ? Math.round((value + Number.EPSILON) * 100) / 100 : 0;
}

function reverseString(value = '') {
    return value.split('').reverse().join('');
}

function extractAndFormatIntegerPart(parts) {
    let integerPart = parts[0];
    if (integerPart.length > 3) {
        const reversedIntegerPart = reverseString(integerPart);
        const threeDigitsGroups = reversedIntegerPart.match(/\d{3}/g);
        const restWithoutThreeDigitsGroups = reversedIntegerPart.substring(threeDigitsGroups.join('').length);
        integerPart = reverseString(restWithoutThreeDigitsGroups);
        threeDigitsGroups.reverse().forEach((group) => {
            integerPart += ` ${reverseString(group)}`;
        });
    }

    return integerPart;
}

function extractAndFormatDecimalPart(parts) {
    return parts.length > 1 ? `.${parts[1]}` : '';
}

export function formatPrice(price = 0, options = { withMinusSymbol: false, withEuroSymbol: true, isTTC: false }) {
    const roundedPrice = roundToTwoDecimal(price);
    const priceParts = String(roundedPrice).split('.');

    const integerPart = extractAndFormatIntegerPart(priceParts);
    const decimalPart = extractAndFormatDecimalPart(priceParts);
    const pricePrefixes = [];
    if (options.withMinusSymbol) pricePrefixes.push('-');

    return `${pricePrefixes.join('')} ${integerPart}${decimalPart} ${options.withEuroSymbol ? '€' : ''} ${options.isTTC ? 'TTC' : ''}`.trim();
}
export function getFontSize({ size = 'sm' }) {
    return iconSizes[size];
}
