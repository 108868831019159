export const documentsStatusesEnum = Object.freeze({
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
    CONFIRMED: 'CONFIRMED',
    ARCHIVED: 'ARCHIVED',
    CANCELLED_FROM_BACK_OFFICE: 'CANCELLED_FROM_BACK_OFFICE',
    EXPIRED: 'EXPIRED',
});

export const buildingSectorsEnum = Object.freeze({
    RESIDENTIAL: 'Résidentiel',
    TERTIARY: 'Tertiaire',
    INDUSTRY: 'Industrie',
    AGRICULTURE: 'Agriculture',
    NETWORK: 'Réseau',
    TRANSPORT: 'Transport',
});

export const contractPricesTypesEnum = Object.freeze({
    CLASSIC: 'Prix du MWh cumac - Volume classique',
    PRECARITY: 'Prix du MWh cumac - Volume précarité',
    'CLASSIC CDP': 'Prix du MWh cumac - Coup de Pouce - Volume classique',
    'PRECARITY CDP': 'Prix du MWh cumac - Coup de Pouce - Volume précarité',
});

export const documentTypesEnum = Object.freeze({
    RGE_CERTIFICATE: 'RGE_CERTIFICATE',
    KBIS_EXTRACT: 'KBIS_EXTRACT',
    INSURANCE: 'INSURANCE',
    CONTRACT: 'CONTRACT',
    CONTRACT_AMENDMENT: 'CONTRACT_AMENDMENT',
    RGE_CERTIFICATE_OF_SUBCONTRACTOR: 'RGE_CERTIFICATE_OF_SUBCONTRACTOR',
    PRODUCT: 'PRODUCT',
});

export const warrantyAreaEnum = Object.freeze({
    WHOLE_FRANCE: 'Toute la France',
    METROPOLITAN_FRANCE: 'France Métropolitaine',
    DOM_TOM: 'DOM-TOM',
});

export const residentialCoupDePouceEnum = Object.freeze({
    ISOLANT: 'Isolation',
    POMPE_CHALEUR: 'Chauffage',
    EFFICIENT_RENOVATION_OF_INDIVIDUAL_HOUSES: "Rénovation performante d'une maison individuelle",
    EFFICIENT_RENOVATION_OF_COLLECTIVE_RESIDENTIAL_BUILDINGS: 'Rénovation performante de bâtiment résidentiel collectif',
    THERMOSTAT_WITH_HIGH_PERFORMANCE_CONTROL: 'Thermostat avec régulation performante',
});

export const tertiaryCoupDePouceEnum = Object.freeze({
    POMPE_CHALEUR: 'Chauffage',
});

export const allowedFileType = Object.freeze(['application/pdf', 'image/png', 'image/jpeg', 'image/jpg']);

export const dossierCEEStatuses = Object.freeze({
    PROCESSING: 'PROCESSING',
    COMPLETED: 'COMPLETED',
    SENT: 'SENT',
    SENT_FOR_BACK_OFFICE_VERIFICATION: 'SENT_FOR_BACK_OFFICE_VERIFICATION',
    CANCELLED: 'CANCELLED',
    CANCELLED_FROM_BACK_OFFICE: 'CANCELLED_FROM_BACK_OFFICE',
});

export const dossierCEEDocumentTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    INVOICE: 'INVOICE',
    TAX_NOTICE: 'TAX_NOTICE',
    SWORN_STATEMENT: 'SWORN_STATEMENT',
    CADASTRAL_PLOT: 'CADASTRAL_PLOT',
    POST_RENOVATION_REPORT: 'POST_RENOVATION_REPORT',
    SITE_PHOTO: 'SITE_PHOTO',
    SITE_PHOTO_BEFORE_RENOVATION: 'SITE_PHOTO_BEFORE_RENOVATION',
    SITE_PHOTO_AFTER_RENOVATION: 'SITE_PHOTO_AFTER_RENOVATION',
    CADRE_CONTRIBUTION: 'CADRE_CONTRIBUTION',
    SIZING_NOTE: 'SIZING_NOTE',
    SOLAR_CERTIFICATION: 'SOLAR_CERTIFICATION',
    SOLAR_FACTOR_CALCULATION_NOTE: 'SOLAR_FACTOR_CALCULATION_NOTE',
    DOMICILIATION_CERTIFICATE: 'DOMICILIATION_CERTIFICATE',
    OTHER: 'OTHER',
    GREEN_FLAME_LABEL_SEVEN: 'GREEN_FLAME_LABEL_SEVEN',
    TECHNICAL_SHEET: 'TECHNICAL_SHEET',
    ADVANCE_PAYMENT_INVOICE: 'ADVANCE_PAYMENT_INVOICE',
    MOVING_CERTIFICATE: 'MOVING_CERTIFICATE',
    CLAIM_ASSIGNMENT: 'CLAIM_ASSIGNMENT',
    RENOVATION_GLOBAL_AUDIT: 'RENOVATION_GLOBAL_AUDIT',
    RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO: 'RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO',
    RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE: 'RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE',
    RENOVATION_GLOBAL_COFRAC_BEFORE_WORK: 'RENOVATION_GLOBAL_COFRAC_BEFORE_WORK',
    RENOVATION_GLOBAL_COFRAC_AFTER_WORK: 'RENOVATION_GLOBAL_COFRAC_AFTER_WORK',
    RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST: 'RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST',
    RENOVATION_GLOBAL_TECHNICIANS: 'RENOVATION_GLOBAL_TECHNICIANS',
    COMMISSIONING_REPORT: 'COMMISSIONING_REPORT',
    SUMMARY_STATEMENT: 'SUMMARY_STATEMENT',
    POST_RENOVATION_CONTROL_REPORT: 'POST_RENOVATION_CONTROL_REPORT',
});

export const otherDocumentTypes = Object.freeze({
    SOLAR_CERTIFICATION: 'SOLAR_CERTIFICATION',
});

export const dossierCEEDocumentFileTypes = Object.freeze({
    PDF: 'PDF',
    PDFS: 'PDFS',
    IMAGE: 'IMAGE',
    IMAGES: 'IMAGES',
});

export const quotationStatuses = Object.freeze({
    CREATED: 'CREATED',
    DRAFT: 'DRAFT',
    COMPLETED: 'COMPLETED',
    SENT: 'SENT',
    SIGNED: 'SIGNED',
    SIGNED_AND_FINALIZED_RELATED_INVOICE: 'SIGNED_AND_FINALIZED_RELATED_INVOICE',
});

export const invoiceStatuses = Object.freeze({
    DRAFT: 'DRAFT',
    FINALIZED: 'FINALIZED',
    SENT: 'SENT',
    PAID: 'PAID',
    CANCELLED: 'CANCELLED',
    RECTIFIED: 'RECTIFIED',
    SENT_FOR_BACK_OFFICE_VERIFICATION: 'SENT_FOR_BACK_OFFICE_VERIFICATION',
});

export const housingSituations = Object.freeze({
    PRECARITY: 'PRECARITY',
    GREAT_PRECARITY: 'GREAT_PRECARITY',
    CLASSIC: 'CLASSIC',
});

export const entityTypes = Object.freeze({
    QUOTATIONS: 'QUOTATIONS',
    INVOICES: 'INVOICES',
    DOSSIERS_CEE: 'DOSSIERS_CEE',
});

export const NO_SIGNATORY = 'Aucun signataire';

export const contractNegotiators = Object.freeze({
    RENOLIB: 'RENOLIB',
    PRO: 'PRO',
});

export const customerTypes = Object.freeze({
    CUSTOMER_PP: 'CUSTOMER_PP',
    CUSTOMER_PM: 'CUSTOMER_PM',
});

export const coupDePouceTypesTranslations = Object.freeze({
    INSULATION: 'Isolation',
    HEATING: 'Chauffage',
    RENOVATION_GLOBAL: "Rénovation performante d'une maison individuelle",
    CONNECTED_HEATING_CONTROL: 'Pilotage connecté du chauffage pièce par pièce',
});

export const productCategoriesLabel = Object.freeze({
    CALORIFUGEAGE: 'Calorifugeage',
    CHAUDIERE_INDIVIDUELLE: 'Chaudière individuelle',
    CHAUFFE_EAU_SOLAIRE_INDIVIDUEL: 'Chauffe-eau solaire individuel',
    CHAUFFE_EAU_THERMODYNAMIQUE: 'Chauffe-Eau Thermodynamique',
    FENETRE: 'Fenêtres, autres fenêtres ou portes-fenêtres',
    ISOLANT: 'Isolant',
    POMPE_CHALEUR: 'Pompe à chaleur',
    TOITURE: 'Toiture ou éléments de toiture',
    VENTILATION: 'Ventilation',
    APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS: 'Appareil indépendant de chauffage au bois',
    SYSTEME_SOLAIRE_COMBINE: 'Système solaire combiné',
    CHAUDIERE_BIOMASSE_INDIVIDUELLE: 'Chaudière biomasse individuelle ',
    CHAUFFE_EAU_SOLAIRE: "Chauffe-eau solaire individuel (France d'outre-mer)",
    WATER_SAVING_SYSTEMS: 'Systèmes hydro-économes',
    HOUSSE_ISOLANTE: 'Housse isolante',
    REGULATION_SYSTEM: 'Système de régulation par programmation horaire pièce par pièce',
});

export const productCategories = Object.freeze({
    ISOLANT: 'ISOLANT',
    POMPE_CHALEUR: 'POMPE_CHALEUR',
    CHAUFFE_EAU_SOLAIRE_INDIVIDUEL: 'CHAUFFE_EAU_SOLAIRE_INDIVIDUEL',
    TOITURE: 'TOITURE',
    FENETRE: 'FENETRE',
    CHAUDIERE_INDIVIDUELLE: 'CHAUDIERE_INDIVIDUELLE',
    VENTILATION: 'VENTILATION',
    CALORIFUGEAGE: 'CALORIFUGEAGE',
    CHAUFFE_EAU_THERMODYNAMIQUE: 'CHAUFFE_EAU_THERMODYNAMIQUE',
    APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS: 'APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS',
    CHAUDIERE_BIOMASSE_INDIVIDUELLE: 'CHAUDIERE_BIOMASSE_INDIVIDUELLE',
    CHAUFFE_EAU_SOLAIRE: 'CHAUFFE_EAU_SOLAIRE',
    WATER_SAVING_SYSTEMS: 'WATER_SAVING_SYSTEMS',
    HOUSSE_ISOLANTE: 'HOUSSE_ISOLANTE',
    REGULATION_SYSTEM: 'REGULATION_SYSTEM',
});

export const productSubCategories = Object.freeze({
    POMPE_CHALEUR_AIR_EAU: 'POMPE_CHALEUR_AIR_EAU',
    POMPE_CHALEUR_EAU_EAU: 'POMPE_CHALEUR_EAU_EAU',
    POMPE_CHALEUR_HYBRIDE: 'POMPE_CHALEUR_HYBRIDE',
    POMPE_CHALEUR_AIR_AIR: 'POMPE_CHALEUR_AIR_AIR',
    POELE_GRANULES: 'POELE_GRANULES',
    POELE_BUCHES: 'POELE_BUCHES',
    FOYER_FERME_OU_INSERT_CHEMINEE_EXTERIEURE: 'FOYER_FERME_OU_INSERT_CHEMINEE_EXTERIEURE',
    CUISINIERE_UTILISEE_COMME_MODE_DE_CHAUFFAGE: 'CUISINIERE_UTILISEE_COMME_MODE_DE_CHAUFFAGE',
    SHOWER_HEAD: 'SHOWER_HEAD',
    JET_REGULATOR: 'JET_REGULATOR',
});

export const productSubCategoriesLabel = Object.freeze({
    POMPE_CHALEUR_AIR_EAU: 'Pompe à chaleur air/eau',
    POMPE_CHALEUR_EAU_EAU: 'Pompe à chaleur eau/eau',
    POMPE_CHALEUR_HYBRIDE: 'Pompe à chaleur hybride',
    POMPE_CHALEUR_AIR_AIR: 'Pompe à chaleur air/air',
    POELE_GRANULES: 'Poêle à granulés',
    POELE_BUCHES: 'Poêle à bûches',
    FOYER_FERME_OU_INSERT_CHEMINEE_EXTERIEURE: 'Foyer fermé, insert de cheminée extérieure',
    CUISINIERE_UTILISEE_COMME_MODE_DE_CHAUFFAGE: 'Cuisinière utilisée comme mode de chauffage',
    SHOWER_HEAD: 'Pomme de douche',
    JET_REGULATOR: 'Régulateur de jet',
});

export const productSubCategoriesLabelByCategory = Object.freeze({
    POMPE_CHALEUR: {
        POMPE_CHALEUR_AIR_EAU: 'Pompe à chaleur air/eau',
        POMPE_CHALEUR_EAU_EAU: 'Pompe à chaleur eau/eau',
        POMPE_CHALEUR_HYBRIDE: 'Pompe à chaleur hybride',
        POMPE_CHALEUR_AIR_AIR: 'Pompe à chaleur air/air',
    },
    APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS: {
        POELE_GRANULES: 'Poêle à granulés',
        POELE_BUCHES: 'Poêle à bûches',
        FOYER_FERME_OU_INSERT_CHEMINEE_EXTERIEURE: 'Foyer fermé, insert de cheminée extérieure',
        CUISINIERE_UTILISEE_COMME_MODE_DE_CHAUFFAGE: 'Cuisinière utilisée comme mode de chauffage',
    },
    WATER_SAVING_SYSTEMS: {
        SHOWER_HEAD: 'SHOWER_HEAD',
        JET_REGULATOR: 'JET_REGULATOR',
    },
});

export const productVatRates = Object.freeze({
    METROPOLIS_NORMAL_VAT_RATE: 0.2,
    RENOVATION_SPECIFIC_VAT_RATE: 0.1,
    ZNI_NORMAL_VAT_RATE: 0.085,
    METROPOLIS_REDUCED_VAT_RATE: 0.055,
    ZNI_REDUCED_VAT_RATE: 0.021,
    ZERO_VAT_RATE: 0,
});

export const ventilationTypes = Object.freeze({
    TYPE_A: 'Type A',
    TYPE_B: 'Type B',
});

export const fluidOptionsEnums = Object.freeze({
    EAU: 'Eau',
    EAU_GLYCOLEE: 'Eau glycolée',
    AIR: 'Air',
});

export const heatPumpTypesLabel = Object.freeze({
    'medium-high-temperature-heat-pump': 'Pompe à chaleur moyenne ou haute température',
    'low-temperature-heat-pump': 'Pompe à chaleur basse température',
});

export const componentTypes = Object.freeze({
    INPUT: 'input',
    RADIO: 'radio',
    SELECT: 'select',
    TEXTAREA: 'textarea',
});

export const woodHeatingTypesLabel = Object.freeze({
    COMBUSTIBLE_SOUS_FORME_GRANULES: 'Combustible sous forme de granulés',
    COMBUSTIBLE_SOUS_AUTRE_FORME_GRANULES: 'Combustible sous une autre forme que des granulés',
});

export const productFileTypes = Object.freeze({
    GREEN_FLAME_LABEL_SEVEN: 'GREEN_FLAME_LABEL_SEVEN',
    CERTIFICATION: 'CERTIFICATION',
});

export const productFileTypesValues = Object.freeze({
    'Label flamme verte 7*': 'GREEN_FLAME_LABEL_SEVEN',
    Certfication: 'CERTIFICATION',
});

export const rackingProfilesLabels = Object.freeze({
    M: 'M (95% d’efficacité énergétique)',
    L: 'L (100% d’efficacité énergétique)',
    XL: 'XL (110% d’efficacité énergétique)',
});

export const mandatMprMixteEvents = Object.freeze({
    ORGANIZATION_MANDAT_MPR_MIXTE_ACTIVATED: 'ORGANIZATION_MANDAT_MPR_MIXTE_ACTIVATED',
    ORGANIZATION_MANDAT_MPR_MIXTE_DEACTIVATED: 'ORGANIZATION_MANDAT_MPR_MIXTE_DEACTIVATED',
});

export const roles = Object.freeze({
    ADMIN: 'ADMIN',
    TECHNICIAN: 'TECHNICIAN',
});

export const functionalAlimentationTypesLabel = Object.freeze({
    AUTOMATIC_ALIMENTATION_PELLET_BOILER: 'Alimentation automatique – chaudières à granulés',
    MANUEL_ALIMENTATION_FUEL_BOILER: 'Alimentation manuelle – chaudières à bûches',
});

export const documentMPRTypes = Object.freeze({
    MAPRIME_RENOV_CERTIFICATE: 'MAPRIME_RENOV_CERTIFICATE',
    PROOF_OF_OWNERSHIP: 'PROOF_OF_OWNERSHIP',
    JOINT_OWNER_SHIP_CERTIFICATE: 'JOINT_OWNER_SHIP_CERTIFICATE',
    OWNER_LESSOR_COMMITMENT_CERTIFICATE: 'OWNER_LESSOR_COMMITMENT_CERTIFICATE',
    ANAH_GRANT_LETTER: 'ANAH_GRANT_LETTER',
    ANAH_REJECTION_LETTER: 'ANAH_REJECTION_LETTER',
    CONTROL_REPORT: 'CONTROL_REPORT',
    ANAH_PAYMENT_LETTER: 'ANAH_PAYMENT_LETTER',
    RENOLIB_PAYMENT_LETTER: 'RENOLIB_PAYMENT_LETTER',
    CLAIM_ASSIGNMENT: 'CLAIM_ASSIGNMENT',
    SITE_PHOTO_BEFORE_RENOVATION: 'SITE_PHOTO_BEFORE_RENOVATION',
    SITE_PHOTO_AFTER_RENOVATION: 'SITE_PHOTO_AFTER_RENOVATION',
    BANK_STATEMENT: 'BANK_STATEMENT',
    CADRE_CONTRIBUTION: 'CADRE_CONTRIBUTION',
    OTHER: 'OTHER',
});

export const documentMPRTypesTranslations = Object.freeze({
    MAPRIME_RENOV_CERTIFICATE: "Mandat MaPrimeRénov' signé",
    PROOF_OF_OWNERSHIP: 'Justificatif de propriété',
    JOINT_OWNER_SHIP_CERTIFICATE: "Attestation d'indivision",
    OWNER_LESSOR_COMMITMENT_CERTIFICATE: "Attestation d'engagement propriétaire bailleur",
    ANAH_GRANT_LETTER: 'Lettre d’octroi de l’ANAH',
    ANAH_REJECTION_LETTER: 'Lettre de refus de l’ANAH',
    CONTROL_REPORT: 'Rapport de contrôle',
    ANAH_PAYMENT_LETTER: 'Lettre de versement de l’ANAH',
    RENOLIB_PAYMENT_LETTER: 'Lettre de versement de RENOLIB',
    CLAIM_ASSIGNMENT: 'Cession de créance signée',
    SITE_PHOTO_BEFORE_RENOVATION: 'Photo du site avant travaux',
    SITE_PHOTO_AFTER_RENOVATION: 'Photo du site après travaux',
    BANK_STATEMENT: 'Relevé d’identité bancaire',
    CADRE_CONTRIBUTION: 'Cadre de contribution',
    OTHER: 'Autre',
});

export const NOT_SPECIFIED = 'Non renseigné';

export const representativeTypeTranslation = Object.freeze({
    ADMINISTRATIVE_REPRESENTATIVE: 'Administratif',
    ADMINISTRATIVE_AND_FINANCIAL_REPRESENTATIVE: 'Mixte',
});

export const uploadDocumentFileTypes = Object.freeze({
    PDF: 'PDF',
    IMAGE: 'IMAGE',
});

export const productBackupEnergiesTranslations = Object.freeze({
    ELECTRIC_JOULE: 'Electrique à effet Joule',
    OTHER: 'Autre',
});

export const waterSavingSystemLabelsTranslations = Object.freeze({
    Z_CLASS: 'Classe Z ',
    ZZ_CLASS_OR_EPA_WATERSENSE: 'Classe ZZ ou EPA « Watersense »',
    NON_REGULATED: 'Aérateurs non régulés de classe Z',
    SELF_REGULATING: 'Aérateurs auto-régulés ',
});

export const operationDetailTypesTranslations = Object.freeze({
    NON_EXISTING_REGULATION_SYSTEM_INSTALLATION: "L'achat et la mise en place d'un système neuf de régulation sur un système de chauffage ne disposant pas de régulation",
    EXISTING_REGULATION_SYSTEM_REPLACEMENT: "L'achat et la mise en place d'un système neuf de régulation en remplacement d'un système de régulation existant",
    REGULATION_SYSTEM_CLASS_A: 'Classe A',
    REGULATION_SYSTEM_CLASS_B: 'Classe B',
    REGULATION_SYSTEM_ELECTRIC_HEAT_EMITTER: 'Électrique',
    REGULATION_SYSTEM_HYDRAULIC_HEAT_EMITTER: 'Hydraulique',
    ECO_WATT: 'EcoWatt',
    ECO_GAS: 'EcoGaz',
    ECO_GAS_WATT: 'EcoGaz et EcoWatt',
});

export const identityVerificationTypes = Object.freeze({
    STANDARD_SIGNATURE: 'STANDARD_SIGNATURE',
    AVANCED_SIGNATURE: 'AVANCED_SIGNATURE',
    QUALIFIED_SIGNATURE: 'QUALIFIED_SIGNATURE',
});
